html,
body,
#root {
  height: 100%;
}

#root {
  min-width: 1240px;
}

.c-pointer {
  cursor: pointer;
}

.table {
  table-layout: fixed;
}

.table-hover td {
  cursor: pointer;
}

.w-30px {
  width: 30px;
}

.w-120px {
  width: 120px;
}

.w-160px {
  width: 160px;
}
